<template>

  <div class="px-4 pb-4 recent-education">
    <v-divider class="mb-3"></v-divider>
    <v-row >
      <v-col cols="3" class="resume-title">{{ $t("resume.recentEducation.title") }}</v-col>
      <v-col cols="9">
        <ul v-if="educations">
          <li v-for="(item, i) in educations" :key="i" class="resume-text">
            <div>{{item}}</div>
          </li>
        </ul>
      </v-col>
    </v-row>
  </div>
</template>
<script>

export default {
  methods: {},
  computed: {
    educations  () {
      var temp = this.$t("resume.recentEducation.list");
      return temp;
    },
  },
  mounted () {}
}
</script>
<style lang="scss">
.resume .recent-education {
  font-size: .9rem;
}
</style>

