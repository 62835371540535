<template>
  <div class="px-4">

    <v-row class="pb-4">
      <v-col cols="3" class="resume-title">{{ $t("resume.experience.current.title") }}</v-col>
      <v-col cols="9" class="resume-text">{{ $t("resume.experience.current.text") }}</v-col>
    </v-row>
    <v-divider class="mb-3"></v-divider>


    <v-row class="pb-1">
      <v-col cols="3" class="resume-title">{{ $t("resume.experience.title") }}</v-col>
    </v-row>

  <light-timeline :items='experiences'>
    <template slot='tag' slot-scope='{ item }' align-top>
      <div class="resume-tag top">
        <div class="place">{{item.place}}</div>
        <div class="date">{{item.date}}</div>
      </div>
    </template>

    <template slot='content' slot-scope='{ item }'>
      <div>
        <div class="resume-title ">{{item.company}}</div>
        <div class="resume-subtitle pb-2">{{item.title}}</div>
        <div v-if="item.content" class="resume-text">{{item.content}}</div>
        <ul v-if="item.list">
          <li v-for="(list, i) in item.list" :key="i" class="resume-text">{{list}}</li>
        </ul>
      </div>
    </template>
  </light-timeline>


  </div>
</template>
<script>

export default {
  props:['type'],
  data: () => ({}),
  methods: {},
  computed: {
    experiences  () {
      var temp = this.$t("resume.experience.items");
      console.log('experiences:', temp)
      return temp.filter((item) => {
        return (!item.mode || (this.$route.params.mode && item.mode === this.$route.params.mode))
      });
    },
  },
  mounted () {
    console.log('type in component:',this.$route.params);
  }
}
</script>
<style lang="scss">
</style>
